<template>
  <v-card>
    <v-card-text>
      <Loading
        v-if="loading"
        :loading="loading"
        message="Loading Regions"
      />
      <div v-else-if="regionList.length">
        Showing {{ regionList.length }} active regions
        <v-row>
          <v-col
            v-for="region in regionList"
            :key="region.id"
            cols="12"
            md="6"
            xl="4"
          >
            <v-sheet min-height="200">
              <v-lazy
                v-model="region.isActive"
                :options="{ threshold: .2 }"
              >
                <RegionTile :region="region" />
              </v-lazy>
            </v-sheet>
          </v-col>
        </v-row>
        <p v-if="fetchedAt">
          Fetched at: {{ fetchedAt }}
        </p>
      </div>
      <v-alert
        v-else-if="errored"
        type="error"
      >
        Failed to fetch stories.
      </v-alert>
      <div v-else>
        No stories found.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import RegionTile from '@/components/RegionTile.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    RegionTile,
    Loading,
  },
  computed: {
    ...mapState({
      regionList: state => state.regions.all,
      loading: state => state.regions.loading,
      fetchedAt: state => state.regions.fetchedAt,
      errored: state => state.regions.errored,
    }),
  },
}
</script>
