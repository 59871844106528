<template>
  <div class="quests">
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        Quests
      </v-tab>
      <v-tab>
        Stories
      </v-tab>
      <v-tab v-if="profile.allow_authors">
        Authors
      </v-tab>
      <v-tab>
        Regions
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <quest-list />
      </v-tab-item>
      <v-tab-item>
        <story-list />
      </v-tab-item>
      <v-tab-item v-if="profile.allow_authors">
        <author-list />
      </v-tab-item>
      <v-tab-item>
        <region-list />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AuthorList from '@/components/AuthorList.vue'
import QuestList from '@/components/QuestList.vue'
import RegionList from '@/components/RegionList.vue'
import StoryList from '@/components/StoryList.vue'
import { mapState } from 'vuex'

export default {
  name: 'Quests',
  components: {
    AuthorList,
    QuestList,
    RegionList,
    StoryList,
  },
  data: function() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
  },
}
</script>
