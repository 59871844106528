<template>
  <v-card
    outlined
    :to="{ name: 'Author', params: { id: author.id }}"
    class="mb-4"
  >
    <v-img
      :src="(author.image) ? author.image : ''"
      height="200px"
      class="px-3"
    >
      <v-row class="mb-4">
        <v-col>
          <div class="headline">
            {{ author.name }}
          </div>
          <div class="title">
            {{ author.num_quests }} Quests
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    }
  },
}
</script>
