<template>
  <v-card
    outlined
    :to="{ name: 'Region', params: { slug: region.slug }}"
    class="mb-4"
  >
    <v-img
      :src="(region.image_display_url) ? region.image_display_url : ''"
      height="200px"
      class="px-3"
    >
      <v-row>
        <v-col>
          <div class="headline">
            {{ region.name }}
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    region: {
      type: Object,
      required: true,
    }
  },
}
</script>
